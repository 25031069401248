import React from 'react'
import Layout from '../../components/Layout'
import Steps from '../../components/Steps'
import Carousel from 'react-bootstrap/Carousel'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const steps = [
    {
        step: 1,
        title: 'Separa',
        description: 'No mezcles con orgánicos (comida), artículos de higiene, no reciclables, etc.'
    },
    {
        step: 2,
        title: 'Compacta',
        description: 'Vaciado y compactado, es perfecto tu reciclado'
    },
    {
        step: 3,
        title: 'Organiza',
        description: 'Organiza por color',
        list: ['🧴 Plástico = Amarillo',
                '⚙️ Metal, Vidrio = Verde',
                '📦 Papel y Cartón = Fuera de las arpillas',
                '💻 Electrónicos y Electrodomésticos = Pregunta antes'
        ]
    }
];

const GuidePage = () => (
    <Layout>
        <div class="full-width-container margin-top-0">
          <h1
            className="has-text-weight-bold is-size-1"
            style={{
              boxShadow: '0.5rem 0 0 #a7d86d, -0.5rem 0 0 #a7d86d',
              backgroundColor: '#a7d86d',
              color: 'white',
              padding: '1rem',
            }}
          >
             Guía de Separación
          </h1>
        </div>
        <Steps data = {steps} />

        <div class="small-width-container margin-top-0">
        <h2
            className="has-text-weight-bold is-size-2"
            style={{
              boxShadow: '0.5rem 0 0 #a7d86d, -0.5rem 0 0 #a7d86d',
              backgroundColor: '#a7d86d',
              color: 'white',
              padding: '1rem',
            }}
          >
             SI Reciclamos
          </h2>
        </div>
        <Container>
            <Row>     
                <Col sm={12} md={6}>
                    <p class="is-size-2 has-text-weight-semibold centered top-bottom-padding">Metales</p>
                    <Carousel fade controls={false} indicators={true}>
                        <Carousel.Item>
                            <img alt='imagen metales 1' src='/img/guide/guide_metal_1.jpg' width="100%" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img alt='imagen metales 2' src='/img/guide/guide_metal_2.jpg' width="100%" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img alt='imagen metales 3' src='/img/guide/guide_metal_3.jpg' width="100%" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img alt='imagen metales 4' src='/img/guide/guide_metal_4.jpg' width="100%" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img alt='imagen metales 5' src='/img/guide/guide_metal_5.jpg' width="100%" />
                        </Carousel.Item>
                    </Carousel>
                </Col>

                <Col sm={12} md={6}>
                    <p class="is-size-2 has-text-weight-semibold centered top-bottom-padding">Plásticos</p>
                    <Carousel fade controls={false} indicators={true}>
                        <Carousel.Item>
                            <img alt='imagen plásticos 1' src='/img/guide/guide_plastic_1.jpg' width="100%" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img alt='imagen plásticos 2' src='/img/guide/guide_plastic_2.jpg' width="100%" />
                        </Carousel.Item>
                    </Carousel>
                </Col>

            </Row>
            <Row>
                
                <Col sm={12} md={6}>
                    <p class="is-size-2 has-text-weight-semibold centered top-bottom-padding">Papel y Cartón</p>
                    <Carousel fade controls={false} indicators={true}>
                        <Carousel.Item>
                            <img alt='papel y cartón 1' src='/img/guide/guide_cardboard_1.jpg' width="100%" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img alt='papel y cartón 2' src='/img/guide/guide_cardboard_2.jpg' width="100%" />
                        </Carousel.Item>
                    </Carousel>
                </Col>

                <Col sm={12} md={6}>
                    <p class="is-size-2 has-text-weight-semibold centered top-bottom-padding">Vidrio</p>
                    <Carousel fade controls={false} indicators={true}>
                        <Carousel.Item>
                            <img alt='imagen vidrio 1' src='/img/guide/guide_glass_1.jpg' width="100%" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img alt='imagen vidrio 2' src='/img/guide/guide_glass_2.jpg' width="100%" />
                        </Carousel.Item>
                    </Carousel>
                </Col>

            </Row>
        </Container>
        <br/>

        <div class="small-width-container margin-top-0">
        <h2
            className="has-text-weight-bold is-size-2"
            style={{
              boxShadow: '0.5rem 0 0 #a7d86d, -0.5rem 0 0 #a7d86d',
              backgroundColor: '#a7d86d',
              color: 'white',
              padding: '1rem',
            }}
          >
             Buenas Prácticas
          </h2>
        </div>     
        <Container>
            <Row>
                <Col sm={12} md={{ span: 6, offset: 3 }}>
                    <img alt='buenas prácticas' src='/img/guide/guide_best_practices_1.jpg' width="100%" />
                </Col>
            </Row>
        </Container>
        <br/>

        <div class="small-width-container margin-top-0" style={{backgroundColor: '#c1bfce'}}>
          <h2
            className="has-text-weight-bold is-size-2"
            style={{
              boxShadow: '0.5rem 0 0 #c1bfce, -0.5rem 0 0 #c1bfce',
              backgroundColor: '#c1bfce',
              color: 'white',
              padding: '1rem',
            }}
          >
             NO Reciclamos
          </h2>
        </div>
        <Container>
            <Row>     
                <Col sm={12} md={6}>
                    <p class="is-size-2 has-text-weight-semibold centered top-bottom-padding">No Metales</p>
                    <Carousel fade controls={false}>
                        <Carousel.Item>
                            <img alt='imagen metales 1' src='/img/guide/guide_not_metal_1.jpg' width="100%" />
                        </Carousel.Item>
                    </Carousel>
                </Col>

                <Col sm={12} md={6}>
                    <p class="is-size-2 has-text-weight-semibold centered top-bottom-padding">No Plásticos</p>
                    <Carousel fade controls={false} indicators={true}>
                        <Carousel.Item>
                            <img alt='imagen plásticos 1' src='/img/guide/guide_not_plastic_1.jpg' width="100%" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img alt='imagen plásticos 2' src='/img/guide/guide_not_plastic_2.jpg' width="100%" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img alt='imagen plásticos 2' src='/img/guide/guide_not_plastic_3.jpg' width="100%" />
                        </Carousel.Item>
                    </Carousel>
                </Col>

            </Row>
            <Row>
                
                <Col sm={12} md={6}>
                    <p class="is-size-2 has-text-weight-semibold centered top-bottom-padding">No Papel y Cartón</p>
                    <Carousel fade controls={false} indicators={true}>
                        <Carousel.Item>
                            <img alt='papel y cartón 1' src='/img/guide/guide_not_cardboard_1.jpg' width="100%" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img alt='papel y cartón 2' src='/img/guide/guide_not_cardboard_2.jpg' width="100%" />
                        </Carousel.Item>
                    </Carousel>
                </Col>

                <Col sm={12} md={6}>
                    <p class="is-size-2 has-text-weight-semibold centered top-bottom-padding">No Vidrio</p>
                    <Carousel fade controls={false} indicators={true}>
                        <Carousel.Item>
                            <img alt='imagen vidrio 1' src='/img/guide/guide_not_glass_1.jpg' width="100%" />
                        </Carousel.Item>
                    </Carousel>
                </Col>

            </Row>
        </Container>
        <br/>

        <div class="small-width-container margin-top-0" style={{backgroundColor: '#c1bfce'}}>
          <h2
                className="has-text-weight-bold is-size-2"
                style={{
                boxShadow: '0.5rem 0 0 #c1bfce, -0.5rem 0 0 #c1bfce',
                backgroundColor: '#c1bfce',
                color: 'white',
                padding: '1rem',
                }}
            >
                Malas prácticas
          </h2>
        </div>
        <Container>
            <Row>
                <Col sm={12} md={{ span: 6, offset: 3 }}>
                    <img alt='malas prácticas' src='/img/guide/guide_bad_practices_1.jpg' width="100%" />
                </Col>
            </Row>
        </Container>
        <br/>
    </Layout>
)

export default GuidePage