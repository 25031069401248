import React from 'react'
import PropTypes from 'prop-types'

const List = (step) => {
  if(step.step.hasOwnProperty('list'))
  {
    const listItems = step.step.list.map((item, index) =><ListItem key={index} value={item} />);
    return (<ul>{listItems}</ul>);
  }
  else
    return null;
}

const ListItem = (props) => {
  return <li className="is-size-6 has-text-centered">{props.value}</li>;
}

const Steps = ({ data }) => (
  <div className="columns">
    {data.map((step) => (
      <div key={step.plan} className="column">
        <section className="section">
          <h4 className="has-text-centered has-text-weight-semibold">
            {step.title}
          </h4>
          <h2 className="is-size-1 has-text-weight-bold has-text-primary has-text-centered">
            {step.step}
          </h2>
          <p className="has-text-weight-semibold has-text-centered">{step.description}</p>
          <List step={step}/>
          
        </section>
      </div>
    ))}
  </div>
)

Steps.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      step: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      description: PropTypes.string,
      list: PropTypes.array
    })
  ),
}

export default Steps
